import {Button, Col} from "react-bootstrap";

function SelectionScreen({handleSelection}) {

    const onSelect = (selection) => () => {
        handleSelection(selection)
    }

    return (
        <Col className="col-12 text-center">
            <h2>Куда вы мечтаете отправиться?</h2>
            <p className="lead mt-3">Выберите или напишите что-то свое</p>
            <div className="mt-5">
                <Button className="btn btn-light shadow-lg rounded-5 me-2" onClick={onSelect("На море с семьей")}>На море всей семьей</Button>
                <Button className="btn btn-light shadow-lg rounded-5" onClick={onSelect("В романтическое путешествие")}>В романтическое путешествие</Button>
                <Button className="btn btn-light shadow-lg rounded-5 ms-2" onClick={onSelect("На поиски приключений")}>На поиски приключений</Button>
            </div>
        </Col>
    )
}

export default SelectionScreen;