import Markdown from "react-markdown";
import { visitParents } from "unist-util-visit-parents";
import {memo, useEffect, useRef} from "react";

const animateDelay = (delay) => {
    return {
        "--animate-delay": `${delay*100}ms`
    }
}

const MarkdownText = memo(({children, className, onAnimationEnd}) => {
    let index = 0;
    let next = 0;
    const nodes = {}
    const elements = {}

    const divRef = useRef(null)

    const rehypeWrapText = () => (tree) => {
        visitParents(tree, "text", (node, ancestors) => {
            if (ancestors.at(-1).tagName !== "text") {
                const key = JSON.stringify(node)
                let idx = nodes[key]
                if (!idx) {
                    idx = ++index
                    nodes[key] = idx
                }
                node.type = "element";
                node.tagName = "text";
                node.index = idx;
                node.children = [{ type: "text", value: node.value }];
            }
        });
    }

    useEffect(() => {
        if (divRef.current && typeof onAnimationEnd === "function") {
            const list = divRef.current.querySelectorAll(".animate__animated")
            if (list.length) {
                list[list.length - 1].addEventListener("animationend", (e) => {
                    e.stopPropagation()
                    onAnimationEnd()
                }, {once: true})
            }
        }
    }, [divRef])

    return (
        <div ref={divRef}>
            <Markdown
                className={className}
                rehypePlugins={[rehypeWrapText]}
                components={{
                    "text": ({children, node}) => {
                        let element = elements[node.index]
                        if (!element) {
                            element = (
                                <span>
                                    {children.map(c => c.split(" ").map(s => s.trim()).filter(s => !!s).map((span, i) => (
                                        <span className="animate__animated animate__fadeIn animate__delay" style={animateDelay(++next)}>{span} </span>
                                    )))}
                                </span>
                            )
                        }
                        elements[node.index] = element
                        return element
                    },
                    "li": ({children}) => (
                        <li className="animate__animated animate__fadeIn animate__delay" style={animateDelay(++next)}>{children}</li>
                    ),
                    "a": (props) => (
                        <a target="_blank" href={props.href}>{props.children}</a>
                    ),
                    "img": (props) => (
                        <img src={props.src}
                             className="animate__animated animate__fadeIn animate__delay"
                             style={animateDelay(++next)}
                             onError={(event) => event.target.style.display = 'none'}
                        />
                    )
                }}>
                {children}
            </Markdown>
        </div>
    )
}, (old, props) => old.key === props.key)

export default MarkdownText;