import {Button, Card, Carousel, CarouselItem, Col, Container, Row} from "react-bootstrap";
import {useState} from "react";

function Hotels({hotels}) {
    const [toggle, setToggle] = useState(true)
    const grouped = hotels.reduce((acc, curr, index) => (index % 3 === 0 ? acc.push([curr]) : acc[acc.length - 1].push(curr), acc), []);

    return (
        <Container>
            <div className="hotel-toggler m-5 animate__animated animate__fadeIn animate__slower animate__delay-1s">
                <Button variant="outline-light" className="btn-sm rounded-5" onClick={() => setToggle(!toggle)}>{toggle ? 'Скрыть': 'Показать'} отели</Button>
            </div>
            <Carousel className={`hotels-list animate__animated animate__slower ${toggle ? "animate__fadeInUp" : "animate__fadeOutDown"}`} indicators={false} pause="hover" interval={5000}>
                {grouped.map((list, i) => (
                    <CarouselItem>
                        <Row>
                            {list.map(hotel => (
                                <Col lg={4}>
                                    <Card className="shadow-lg hotel-card">
                                        <Carousel slide={false} indicators={false} controls={false} interval={10000}>
                                            {hotel.photos.map(photo => (
                                                <CarouselItem>
                                                    <Card.Img className="hotel-photo" src={photo}/>
                                                </CarouselItem>
                                            ))}
                                        </Carousel>
                                        <Card.Body>
                                            <Card.Title>{hotel.original_name}</Card.Title>
                                            <Card.Text>
                                                <p>{hotel.room_type.name}, {hotel.meal_type.name}</p>
                                                <p>
                                                    <small>Взрослых: {hotel.adults}, {hotel.kids ? `детей: ${hotel.kids}` : 'без детей'}</small><br/>
                                                    <small>Дата заезда: {hotel.start_date}, дней в отеле - {hotel.hotel_duration}</small>
                                                </p>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant="dark" className="float-end">Забронировать за <strong>{hotel.price.total}</strong> руб</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </CarouselItem>
                ))}
            </Carousel>
        </Container>
    )
}

export default Hotels;