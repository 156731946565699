import {Alert, Badge, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import React from "react";
import axios from "axios";
import MarkdownText from "./MarkdownText";

const initialMessage = {
    type: "assistant",
    text: "👋 Я здесь, чтобы создать вместе с вами _путешествие мечты!_ 🏝\n\nДаже если вы еще не знаете, чего именно вам хочется 😉 Напишите, куда вы мечтаете отправиться в своем следующем путешествии?"
}

const Chat = React.forwardRef((props, ref) => {
    const messagesRef  = useRef(null)
    const assistantRef = useRef(null)
    const userRef = useRef(null)
    const divRef = useRef(null)
    const inputRef = useRef(null)

    const [location, setLocation] = useState()
    const [messages, setMessages] = useState([initialMessage])
    const [input, setInput] = useState("")
    const [conversationId, setConversationId] = useState()
    const [inProgress, setProgress] = useState(false)
    const [showProgress, toggleShowProgress] = useState(false)

    useEffect(() => {
        if (!location) {
            axios.get("http://ip-api.com/json/?lang=ru").then(res => {
                setLocation(res.data.city)
            })
        }
    }, [location])

    useEffect(() => {
        const list = messagesRef.current
        const div = divRef.current
        const user = userRef.current
        const assistant = assistantRef.current
        if (list && div && user) {
            let height = list.clientHeight - user.offsetHeight - 50
            if (assistant && messages[messages.length - 1].type === "assistant") {
                height -= assistant.offsetHeight
            }
            height = Math.max(50, height)
            div.style.height = `${height}px`
            toggleShowProgress(messages[messages.length - 1].type === "user")
            if (messages[messages.length - 1].type === "user") {
                list.scrollTop = list.scrollHeight
            }
        }
    }, [messages])

    React.useImperativeHandle(ref, () => ({
        addMessage, sendMessage, conversationId
    }))

    const sendMessage = (message) => {
        addMessage(message)
        if (conversationId) {
            conversation(conversationId, message.text)
        } else {
            startConversation(message.text)
        }
    }

    const addMessage = (message) => {
        toggleShowProgress(false)
        setProgress(message.type === "user" || message.inProgress)
        const msgs = messages.filter(m => !m.inProgress)
        if (message.type === "assistant" && !message.inProgress && inputRef.current) {
            inputRef.current.focus()
        }
        msgs.push(message)
        setMessages(msgs)
    }

    const startConversation = async (message) => {
        const res = await axios.get("/chat/create?location=" + location)
        setConversationId(res.data)
        conversation(res.data, message)
        props.onStart(res.data)
    }

    const conversation = async (conversationId, message) => {
        props.onUserMessage(message)
        try {
            await axios.post(`/chat/${conversationId}`, message, {
                headers: {
                    "Content-Type": "text/plain"
                }
            })
        } catch (e) {
            if (e.response && e.response.status === 404) {
                addMessage({type: "assistant", text: "Кажется, время сессии истекло, давайте начнем наш диалог заново."})
                startConversation(message)
            } else {
                addMessage({type: "assistant", text: "Простите, но кажется произошла какая-то ошибка. Не могли бы вы отправить свое сообщение еще раз?"})
            }
        }
    }

    const onInputChange = (e) => {
        setInput(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            let message = input.trim()
            if (message) {
                setInput("")
                sendMessage({type: "user", text: message})
            }
        }
    }

    const handleEndTyping = () => {
        if (inProgress) {
            toggleShowProgress(true)
        }
    }

    const handleOptionClick = (option) => (e) => {
        e.preventDefault()
        e.stopPropagation()
        sendMessage({type: "user", text: option})
    }

    return (
        <Container className="widget">
            <Row className="chat">
                <Col className="align-self-start col-12 messages" ref={messagesRef}>
                    {messages.map((message, index) => {
                        switch (message.type) {
                            case "user": return (
                                <Row key={`user-${index} ${message.text}`} className="justify-content-end animate__animated animate__fadeInUp mb-4 mt-4" ref={userRef}>
                                    <Alert variant="light" className="rounded-start-4 rounded-top-4 user shadow">
                                        {message.text}
                                    </Alert>
                                </Row>
                            )
                            case "assistant": return (
                                <Row ref={assistantRef}>
                                    <MarkdownText key={`assistant-${index}: ${message.text}`} className={"assistant"} onAnimationEnd={handleEndTyping}>{message.text}</MarkdownText>
                                </Row>
                            )
                        }
                    })}
                    <div key={messages.length} className={"spinner-grow spinner-grow-sm animate__animated animate__fadeIn animate__slow animate__delay-1s " + (showProgress ? 'visible' : 'invisible')} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div ref={divRef}></div>
                </Col>
            </Row>
            <Form.Control
                ref={inputRef}
                size="lg"
                type="text"
                value={input}
                autoFocus={true}
                placeholder="Напишите здесь"
                className="rounded-pill shadow-lg animate__animated animate__fadeIn animate__slow"
                onChange={onInputChange}
                onKeyDown={handleKeyDown}>
            </Form.Control>
        </Container>
    )
})

export default Chat;