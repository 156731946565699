import {Button, Col, Row} from "react-bootstrap";

function StartScreen({handleStart}) {
    return (
        <Col className="col-12 text-center">
            <h2>Создайте путешествие мечты</h2>
            <p className="lead mt-3">Вместе с <strong>onlinetours</strong></p>
            <Button className="btn-lg btn-light shadow-lg rounded-5 mt-5" onClick={handleStart}>Давайте помечтаем!</Button>
        </Col>
    )
}

export default StartScreen;