import './App.css';
import 'animate.css';
import {useEffect, useRef, useState} from "react";
import Chat from "./Chat";
import {Container, Row} from "react-bootstrap";
import StartScreen from "./StartScreen";
import SelectionScreen from "./SelectionScreen";
import axios from "axios";
import Hotels from "./Hotels";
import "./KenBurnsCarousel.ts";

const canvasStyleStart = {
    width: "100vw",
    height: "100%",
    position: "absolute",
}

const canvasStyleEnd = Object.assign({}, canvasStyleStart, {
    width: "70vw",
    transition: "3s",
})

const staticImages = {
    initial: [
        "https://images.unsplash.com/photo-1558285549-2a06f9a5fe65",
        "https://images.unsplash.com/photo-1483449720373-5d67a7f26fdb",
        "https://images.unsplash.com/photo-1436491865332-7a61a109cc05"
    ],
    start: [
        "https://images.unsplash.com/photo-1452421822248-d4c2b47f0c81",
        "https://images.unsplash.com/photo-1504609773096-104ff2c73ba4",
        "https://images.unsplash.com/photo-1578894381163-e72c17f2d45f"
    ],
}

function App() {
    const [isFetching, setFetching] = useState(false)
    const [userMessage, setUserMessage] = useState(null)
    const [isStarted, setStarted] = useState(false)
    const [isReady, setReady] = useState(false)
    const [chatStarted, setChatStarted] = useState(false)
    const [images, setImages] = useState(staticImages.initial)
    const [currentImage, setCurrentImage] = useState({})
    const [screen, setScreen] = useState("start")
    const [hotels, setHotels] = useState()
    const chatRef = useRef(null)
    const carouselRef = useRef(null)

    const getUpdate = async () => {
        if (!chatRef.current || !chatRef.current.conversationId || isFetching) return
        setFetching(true)
        try {
            const res = await axios.get(`/update/${chatRef.current.conversationId}`)
            const update = res.data
            setFetching(false)
            if (!update || update.hasMore) {
                getUpdate()
            }
            if (update) {
                if (update.hotels) {
                    setHotels(update.hotels)
                }
                if (update.images && update.images.length) {
                    update.images.reverse()
                    setImages(update.images)
                }
                if (update.assistant) {
                    chatRef.current.addMessage(Object.assign({type: "assistant"}, update.assistant))
                }
            }
        } catch (e) {
            setFetching(false)
            setTimeout(getUpdate, 3000)
        }
    }

    useEffect(() => {
        userMessage && getUpdate()
    }, [userMessage])

    useEffect(() => {
        if (carouselRef.current) {
            carouselRef.current.onSlideStart = handleShowImage(images)
        }
    }, [images])

    const handleShowImage = (images) => (url) => {
        setCurrentImage(images.find(i => i.url === url) || {})
    }

    const handleTransitionEnd = (e) => {
        if (!isReady && isStarted && e.target.id === "carousel") {
            setReady(true)
            setImages(staticImages.start)
            setScreen("selection")
        }
    }

    const handleSelection = (selection) => {
        chatRef.current.sendMessage({
            type: "user", text: selection
        })
    }

    const handleUserMessage = (message) => {
        setUserMessage({text: message})
    }

    const handleAnimationEnd = () => {
        switch (screen) {
            case "start": setScreen(isStarted ? "" : "start")
                break
            case "selection": setScreen(chatStarted ? "" : "selection")
                break
        }
    }

    const renderScreen = () => {
        if (hotels && hotels.length) {
            return (<Hotels hotels={hotels}/>)
        }

        switch (screen) {
            case "start": return (
                <Row className={isStarted ? "animate__animated animate__fadeOut" : "animate__animated animate__fadeIn animate__delay-1s"}
                     onAnimationEnd={handleAnimationEnd}>
                    <StartScreen handleStart={() => setStarted(true)} />
                </Row>
            )
            case "selection": return (
                <Row className={chatStarted ? "animate__animated animate__fadeOut" : "animate__animated animate__fadeIn animate__delay-3s"}
                     onAnimationEnd={handleAnimationEnd}>
                    <SelectionScreen handleSelection={handleSelection}/>
                </Row>
            )
        }
    }

  return (
    <div className="App">
        <div id="carousel" onTransitionEnd={handleTransitionEnd} style={isStarted ? canvasStyleEnd : canvasStyleStart}>
            <ken-burns-carousel
                ref={carouselRef}
                class={"burns-carousel " + (isStarted ? "rounded-end-5" : "")}
                images={images.map(i => i.url ? i.url : i).join(" ")}>
            </ken-burns-carousel>
            {currentImage && currentImage.title && (
                <div key={currentImage.link} className="place animate__animated animate__fadeIn animate__slower">
                    <a href={currentImage.link} target="_blank">{currentImage.title}</a>
                </div>
            )}
            <Container className="main d-flex justify-content-center align-items-center">
                {renderScreen()}
            </Container>
        </div>
        {isReady && <Chat ref={chatRef} onStart={() => setChatStarted(true)} onUserMessage={handleUserMessage}/>}
    </div>
  );
}

export default App;
